import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import {
	AddedHP,
	AddedNM,
	BlockRecentlyTunedSection,
	Car,
	CarBox,
	CarData,
	CarImage,
	CarsContainer,
	CarSpecifications,
	HP,
	Model,
	Name,
	NM,
	OriHP,
	OriNM
} from '../../components/ACF/BlockRecentlyTuned/BlockRecentlyTuned.styled'
import { getImage } from 'gatsby-plugin-image'
import BlockTitle from '../../components/ACF/BlockTitle/BlockTitle'
import BlockCallToAction from '../../components/ACF/BlockCallToAction/BlockCallToAction'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Icon from '../../components/Icon/Icon'

const BrandPageTemplate = ({ data }) => {
	const brandName = data.brand.name

	const brandData = {
		seo: {
			canonical: `/chiptunen/${data.brand.slug}`,
			cornerstone: false,
			focuskw: `chiptunen ${brandName}`,
			metaDesc: `Het chiptunen van jouw ${brandName} geeft jouw auto meer pit en souplesse. Naast het extra vermogen wat jouw ${brandName} krijgt kan chiptuning een aanzienlijke brandstofbesparing opleveren. Een besparing van wel 5 tot 10% is mogelijk na het chiptunen van jouw ${brandName}.`,
			metaKeywords: `chiptunen ${brandName} - ECU adaptions`,
			metaRobotsNofollow: 'follow',
			metaRobotsNoindex: 'index',
			opengraphTitle: `Chiptunen ${brandName} - ECU adaptions`,
			title: `Chiptunen ${brandName} - ECU adaptions`
		},
		header: {
			backgroundColor: '#000000',
			backgroundOpacity: 0.2,
			fieldGroupName: 'header',
			height: 50,
			heroChoice: 'image',
			heroText: data?.brand?.name,
			isFrontPage: false,
			image: data?.brand?.image,
			pxOrVh: 'vh',
			videoUrl: null
		},
		...data?.brand
	}

	const ctaData = { callToAction: data.wpCtaPost }

	const blockTitleData = {
		backgroundShape: false,
		paddingBottom: 10,
		paddingTop: 60,
		text: `RECENTE CHIPTUNINGS VAN <span class="accented">${brandData.name}</span>`
	}

	const recentlyTunedData = data.allWpRecentlyTunedPost.edges.map(edge => edge.node)

	return (
		<PageLayout pageData={brandData}>
			<BrandPageContentWrapper padding={recentlyTunedData.length > 0 ? '80px 0' : '80px 0 0 0'}>
				<Title>{brandData.name}</Title>
				<ContentContainer>
					<TextContainer>
						{' '}
						<TextTitle>Chiptunen {brandData.name}</TextTitle>
						<Text>
							{`Het chiptunen van je geliefde ${brandData.name} is een fascinerende manier om de prestaties van je
							voertuig naar een hoger niveau te tillen. Chiptunen, ook wel bekend als remapping, is een geavanceerde
							techniek waarbij de software in de motorregelingseenheid (ECU) van je ${brandData.name} wordt aangepast om de
							motorprestaties te optimaliseren. Deze aanpassingen vertalen zich in een verbeterde motorrespons, extra
							vermogen en een algehele verfijning van het rijgedrag van je ${brandData.name}`}
						</Text>
						<Text>
							{`Een van de meest opvallende voordelen
							van het chiptunen van je ${brandData.name} is het extra vermogen dat je auto krijgt. Dit vertaalt zich in een merkbare
							toename van de acceleratie en algemene kracht, waardoor je ${brandData.name} een sterkere, meer dynamische rijervaring
							biedt. Het voegt een extra dimensie toe aan je rijplezier en maakt elke rit opwindender. Maar dat is nog
							niet alles; chiptunen kan ook een aanzienlijke brandstofbesparing opleveren.`}
						</Text>
						<Notice>
							{`Let op; chiptuners kunnen hier geen harde
							garanties bieden. Er zijn tal van externe factoren die het brandstofverbruik van je ${brandData.name} kunnen
							beïnvloeden, zowel positief als negatief. Denk hierbij aan variabelen zoals rijomstandigheden, je rijstijl
							en de kwaliteit van de gebruikte brandstof. Dus hoewel chiptunen ongetwijfeld voordelen met zich
							meebrengt, is het belangrijk om realistische verwachtingen te hebben en te begrijpen dat individuele
							resultaten kunnen variëren. Toch blijft chiptunen een spannende en doeltreffende manier om het potentieel
							van je geliefde ${brandData.name} volledig te benutten.`}
						</Notice>
					</TextContainer>
					<FeaturesContainer>
						<TextTitle>
							Dit zijn de voordelen van het op maat chiptunen van jouw {brandData.name} door ECU Adaptions:
						</TextTitle>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer vermogen
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer trekkracht
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Minder brandstofverbruik
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer rijplezier en comfort
						</FeatureItem>
					</FeaturesContainer>
				</ContentContainer>
				<BlockCallToAction data={ctaData} />
				{recentlyTunedData && recentlyTunedData.length > 0 && (
					<>
						<BlockTitle data={blockTitleData} />
						<BlockRecentlyTunedSection paddingBottom={10} paddingTop={10}>
							<CarsContainer>
								{recentlyTunedData.map((recentlyTunedPost, index) => (
									<CarBox key={index}>
										<CarImage
											image={getImage(recentlyTunedPost.postContent.image.localFile)}
											alt={recentlyTunedPost.postContent.image.altText}
										/>
										<CarData>
											<Car>
												<Name>{recentlyTunedPost.postContent.brand}</Name>
												<Model>{recentlyTunedPost.postContent.model}</Model>
											</Car>
											<CarSpecifications>
												<HP>
													<OriHP>{recentlyTunedPost.postContent.originalHp}PK</OriHP>
													<AddedHP>+{recentlyTunedPost.postContent.addedHp}PK</AddedHP>
												</HP>
												<NM>
													<OriNM>{recentlyTunedPost.postContent.originalNm}NM</OriNM>
													<AddedNM>+{recentlyTunedPost.postContent.addedNm}NM</AddedNM>
												</NM>
											</CarSpecifications>
										</CarData>
									</CarBox>
								))}
							</CarsContainer>
						</BlockRecentlyTunedSection>
					</>
				)}
			</BrandPageContentWrapper>
		</PageLayout>
	)
}

const BrandPageContentWrapper = styled.section`
	padding: ${props => props.padding};
`

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
		align-items: flex-start;
	}
`

const TextContainer = styled.div`
	flex: 3;
	margin-right: 3rem;
	margin-bottom: 3rem;
`

const FeaturesContainer = styled.div`
	flex: 2;
	margin-bottom: 3rem;
`

const Title = styled.h1`
	margin: 0;
	margin-bottom: 3rem;
`

const TextTitle = styled.h5`
	margin: 0;
	margin-bottom: 1rem;
	max-width: 600px;
`

const Text = styled.p`
	margin: 0;
	margin-bottom: 1.5rem;
`

const Notice = styled.p`
	margin: 0;
	font-style: italic;
	color: rgb(129, 129, 129);
`

const FeatureItem = styled.p`
	display: flex;
	flex-direction: row;
	margin: 0;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	align-items: center;
`

export default BrandPageTemplate

export const query = graphql`
	query BrandQuery($id: String!, $slug: String) {
		brand(id: { eq: $id }) {
			id
			name
			slug
			image {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 1920)
					}
				}
			}
		}
		allWpRecentlyTunedPost(filter: { categories: { nodes: { elemMatch: { name: { eq: $slug } } } } }, limit: 3) {
			edges {
				node {
					title
					databaseId
					slug
					id
					uri
					postContent {
						image {
							altText
							sourceUrl
							localFile {
								childImageSharp {
									gatsbyImageData(width: 700, height: 450)
								}
							}
						}
						addedHp
						addedNm
						brand
						model
						originalHp
						originalNm
					}
				}
			}
		}
		wpCtaPost(slug: { eq: "offerte-aanvragen" }) {
			id
			title
			postContent {
				buttonText
				imageOverlay
				imageOverlayOpacity
				paddingBottom
				paddingTop
				text
				buttonLink {
					url
				}
				image {
					altText
					sourceUrl
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1920)
						}
					}
				}
			}
		}
	}
`
